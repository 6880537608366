function getQuantityType(count) {
  // eslint-disable-next-line no-nested-ternary
  const returnVal = count === 1 ? 0 : count > 10 && count < 21 ? 2 : null
  if (returnVal !== null) {
    return returnVal
  }

  const lastDigit = Number(`${count}`.slice(-1))

  return (lastDigit >= 0 && lastDigit <= 1) ||
  (lastDigit >= 5 && lastDigit <= 9)
    ? 2
    : 1
}

export { getQuantityType }