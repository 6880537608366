<template>
  <header class="header">
    <button v-if="src" class="header__button" @click="handleClick">
      <IconArrow class="header__button__arrow" />
      <span>Wybrany plik</span>
    </button>
    <span v-else>Dodaj plik</span>
  </header>
  <div v-if="error" class="error">{{ error }}</div>
  <div v-if="src" class="drop-wraper">
    <div class="drop-area">
      <div class="drop-area__img">
        <img :src="src" ref="myImg">
      </div>
      <button type="button" class="button" @click="handleClickAdd">umieść</button>
    </div>
  </div>
  <div class="drop-wraper" v-if="isActive && !src">
    <div v-bind="getRootProps()" class="drop-area">
      <input v-bind="getInputProps()" />
      <p v-if="isDragActive" class="drop-area__text">
        <span>Upuść pliki tutaj ...</span>
      </p>
      <p v-else class="drop-area__text">
        <span>Przeciągnij plik tutaj (max 10 Mb)</span>
        <span>lub</span>
        <span class="button">wybierz z komputera</span>
      </p>
    </div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { usePanelStore } from '@/stores/usePanelStore'
import IconArrow from '@/assets/icons/arrow.svg'
import { HTTP, baseUrl } from '@/helpers/http-common';


export default {
  name: "AddFile",
  components: {
    IconArrow
  },
  props: {
    onClose: Function
  },
  data() {
    return {
      src: '',
      getRootProps: null,
      getInputProps: null,
      isDragActive: false,
      isActive: false,
      error: null
    }
  },
  setup() {
    const panelStore = usePanelStore()

    return { panelStore }
  },
  mounted() {
    this.initDropzone()
  },
  methods: {
    handleClickAdd() {
      this.panelStore.setElement({
        element: {
          id: 1,
          name: '1.',
          size: '',
          width: this.$refs.myImg.width * 6,
          height: this.$refs.myImg.height * 6,
          img: this.src,
          faces: false,
          custom: true,
        },
        x: 0,
        y: 0,
      })
      this.onClose()
    },
    handleClick() {
      this.src = ''
    },
    uploadedImage(file) {
      const formData = new FormData()
      formData.append('file', file)
      HTTP('/photosaddtemp', {
        headers: {
          accept: 'application/ld+json',
          "Content-Type": "multipart/form-data",
        },
        method: 'POST',
        data: formData,
      })
      .then(resp => {
        this.src = `${baseUrl}${resp.data.url}`
        this.error = null
      })
      .catch(() => {
        this.error = 'Błąd. Spróbuj dodać mniejsze zdjęcie.'
      })
    },
    initDropzone() {
      const onDrop = (acceptFiles) => {
        const file = acceptFiles[0]
        this.uploadedImage(file)
      }

      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ['image/jpeg', 'image/png', 'image/jpg'],
        maxSize: 10 * 1024 * 1024,
        multiple: false,
      });
      this.getRootProps = getRootProps
      this.getInputProps = getInputProps
      this.isDragActive = isDragActive
      this.isActive = true
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  color: $red;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-top: 30px;
}
  .header {
    @include flex-start;
    height: 61px;
    padding-left: 34px;
    background: $greyBack;
    border-bottom: 0.5px solid $grey;
    span {
      font-size: rem(12px);
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 700;
      color: $brown;
    }
    &__button {
      @include flex-start;
      &__arrow {
        transform: rotate(180deg);
        margin-right: 20px;
        transition: all 0.2s;
      }
    }
  }
  .drop-wraper {
    width: 100%;
    height: 368px;
  }
  .drop-area {
    @include flex-center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &__text {
      font-size: rem(18px);
      line-height: rem(51px);
      span {
        display: block;
        text-align: center;
      }
    }
    .button {
      @include flex-center;
      height: 46px;
      min-width: 260px;
      border: 0.5px solid $black;
      border-radius: 23px;
      font-size: rem(14px);
      letter-spacing: 0.05em;
      margin-top: 20px;
      background: $white;
      transition: all 0.2s;
      line-height: 1;
      cursor: pointer;
      &:hover {
        background: $black;
        color: $white;
      }
    }
    &__img {
      @include flex-center;
      height: 200px;
      width: 260px;
      margin-bottom: 23px;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
</style>