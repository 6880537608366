<template>
  <div class="notification" :class="item.type">
    <div class="notification__text">
      {{ item.info }}
    </div>
  </div>
</template>

<script>
import { useNotificationStore } from '@/stores/useNotificationStore';

export default {
  name: "Notification",
  props: {
    item: Object
  },
  setup() {
    const notificationsStore = useNotificationStore()
    return {
      notificationsStore
    }
  },
  mounted() {
    setTimeout(() => {
      this.notificationsStore.removeNotification(this.item.id)
    }, 6000)
  }
}
</script>

<style scoped lang="scss">
.notification {
  @include flex-center;
  position: fixed;
  z-index: 1000;
  width: 300px;
  height: 70px;
  top: 10px;
  left: 10px;
  background: $white;
  border-radius: 10px;
  box-shadow: 0 0 15px 2px $black;
  font-size: rem(18px);
  &.success {
    background: $notificationSuccess;
  }
  &.error {
    background: $notificationError;
  }
}
</style>