<template>
  <div class="menu-context" :style="getPosition">
    <div class="menu-context__tooltip" data-text="przenieś element">
      {{ panelStore.getMenuText }}
    </div>
    <div class="menu-context__buttons">
      <button
          type="button"
          @mouseleave="handleMosueLeave"
          @mouseenter="handleMouseEnter"
          @click="handleRotate(90)"
          class="menu-context__buttons__item"
          data-text="obróć w prawo"
          v-if="!isMoreActive"
      >
        <IconRight class="svg" />
      </button>
      <button
          type="button"
          @mouseleave="handleMosueLeave"
          @mouseenter="handleMouseEnter"
          @click="handleCopy"
          class="menu-context__buttons__item"
          :data-text="isMoreActive ? 'skopiuj elementy' : 'skopiuj element'"
      >
        <IconCopy class="svg" />
      </button>
      <button
          type="button"
          @mouseleave="handleMosueLeave"
          @mouseenter="handleMouseEnter"
          class="menu-context__buttons__item"
          data-text="obróć w lewo"
          @click="handleRotate(-90)"
          v-if="!isMoreActive"
      >
        <IconLeft class="svg" />
      </button>
      <button
          type="button"
          @mouseleave="handleMosueLeave"
          @mouseenter="handleMouseEnter"
          class="menu-context__buttons__item"
          :data-text="isMoreActive ? 'usuń elementy' : 'usuń element'"
          @click="handleRemove"
      >
        <IconRemove class="svg" />
      </button>
    </div>
  </div>
</template>

<script>
import IconRight from '@/assets/icons/right.svg'
import IconLeft from '@/assets/icons/left.svg'
import IconRemove from '@/assets/icons/remove.svg'
import IconCopy from '@/assets/icons/copy.svg'
import { usePanelStore } from '@/stores/usePanelStore';
import { getSizeFromMm } from '@/helpers/getSizeFromMm';

const default_text = 'przenieś element'

export default {
  name: "MenuContext",
  components: {
    IconRight,
    IconLeft,
    IconRemove,
    IconCopy,
  },
  setup() {
    const panelStore = usePanelStore()
    return { panelStore }
  },
  props: {
    zoom: Number,
    isMoreActive: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      text: default_text,
    }
  },
  computed: {
    getPosition() {
      const activeElement = this.panelStore?.getActiveElements[0]
      const scrollLeft = this.panelStore?.getScrollLeft || 0
      const scrollTop = this.panelStore?.getScrollTop || 0
      const x = activeElement.x + 70 + getSizeFromMm(activeElement.element.width)
      const y = activeElement.y

      const panelWidth = this.panelStore?.getWidth + scrollLeft

      let left = 0

      if (panelWidth - 100 > (x * this.zoom)) {
        left = x * this.zoom
      } else {
        left = (activeElement.x * this.zoom) - 100
      }

      return {
        left: `${left - scrollLeft}px`,
        top: `${(y * this.zoom) - scrollTop}px`,
      }
    }
  },
  methods: {
    handleCopy() {
      const activeElements = this.panelStore.getActiveElements
      let newElements = []
      activeElements.forEach(el => {
        const newItem = {...el, x: el.x + 30, y: el.y + 30, active: true}
        delete newItem.id
        newElements.push(newItem)
        this.panelStore.updateElementById(el.id, { active: false })
      })
      this.panelStore.setMoreElements(newElements)
    },
    handleMosueLeave() {
      this.panelStore.setMenuText(default_text)
    },
    handleMouseEnter(e) {
      this.panelStore.setMenuText(e.target.dataset.text)
    },
    handleRemove() {
      let removeElements = []
      const activeElements = this.panelStore.getActiveElements
      activeElements.forEach(el => {
        const removeItemId = el.id
        removeElements.push(removeItemId)
      })
      this.panelStore.removeMoreElements(removeElements)
    },
    handleRotate(i) {
      const activeElement = this.panelStore?.getActiveElements[0]
      if (activeElement) {
        this.panelStore.updateElementById(activeElement.id, { rotate: activeElement.rotate + i })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .menu-context {
    @include flex-center;
    flex-direction: column;
    width: 138px;
    position: absolute;
    z-index: 30;
    &__tooltip {
      @include flex-center;
      @include zirkon(200);
      font-size: rem(14px);
      pointer-events: none;
      padding: 8px 20px;
      background: $white;
      //max-width: 129px;
      white-space: nowrap;
      width: 100%;
      border: 0.5px solid $brown;
      letter-spacing: 0.04em;
      text-transform: lowercase;
      box-shadow: 0 2px 2px rgba(48, 49, 53, 0.25);
      margin-bottom: 18px;
    }
    &__buttons {
      @include flex-center;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      &__item {
        @include flex-center;
        width: 40px;
        height: 40px;
        border: 0.5px solid #303135;
        background: $greyBack;
        transition: all 0.2s;
        .svg {
          fill: $black;
          transition: all 0.2s;
        }
        &:hover {
          border-color: $brown;
          background: $brown;
          .svg {
            fill: $white !important;
          }
        }
      }
    }
  }
</style>