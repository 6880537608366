<template>
<div class="panel">
  <Transition name="fade">
    <MenuContext
        v-if="panelStore.getActiveElements.length > 0"
        :zoom="zoom"
        :isMoreActive="panelStore.getActiveElements.length > 1"
    />
  </Transition>
  <div class="panel__panel__slider">
    <Slider :on-change="handleChangeZoom" :zoom="zoom" />
  </div>
  <div class="panel__panel" ref="panel">
    <Transition>
      <InfoFile v-if="openInfoFile" :on-close="handleOpenInfoFile" :on-download="handleDownloadClick" :on-sendemail="handleSendEmailClick" />
    </Transition>
    <div
        class="dropzone"
        ref="myDropzone"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mousemove="handleMouseMove"
        :style="getSizesDropzones"
    >
      <div class="selected" v-if="drag && panelStore.getActiveElements.length === 0" :style="getDragStyle"></div>
      <ItemElement
          v-for="item in panelStore.getElements"
          :item="item"
          :key="item.id"
          :zoom="zoom"
          :isMoreActive="panelStore.getActiveElements.length > 1"
          :style="drag && 'pointer-events: none;'"
      />
    </div>
  </div>
  <div class="panel__buttons">
    <Tooltip helper-text="dodaj obraz" site="left" :on-click="handleOpenAddFileModal">
      <span class="btn">
        <IconAddFile style="" />
      </span>
    </Tooltip>
    <Tooltip helper-text="przenieś pod spód" site="left" :on-click="handleDownClick">
      <span class="btn">
        <IconUnder />
      </span>
    </Tooltip>
    <Tooltip helper-text="Przenieś na wierzch" site="left" :on-click="handleUpClick">
      <span class="btn">
        <IconUp />
      </span>
    </Tooltip>
    <Tooltip :disabled="!(historyLength > 1)" helper-text="cofnij ostatni ruch" site="left" :on-click="handleBackClick">
      <span class="btn">
        <IconBack />
      </span>
    </Tooltip>
    <Tooltip helper-text="wyczyść obszar roboczy" site="left" :on-click="handleRemoveClick">
      <span class="btn">
        <IconRemove />
      </span>
    </Tooltip>
    <div class="flex-grow"></div>
    <Tooltip helper-text="zobacz zestawienie płytek" site="left" :on-click="handleOpenInfoFile">
      <span class="btn">
        <IconInfo />
      </span>
    </Tooltip>
    <Tooltip helper-text="pobierz projekt" site="left" :on-click="handleDownloadClick">
      <span class="btn">
        <IconDownload />
      </span>
    </Tooltip>
    <Tooltip helper-text="wyślij na maila" site="left" :on-click="handleSendEmailClick">
      <span class="btn">
        <IconSend />
      </span>
    </Tooltip>
  </div>
  <PDF v-if="pdf" :pdf="pdf" :imageToPdf="imageToPdf" :on-disable="doneGeneratePdf" />
</div>
  <Modal :on-close="handleOpenAddFileModal" :open="openAddFileModal" size="medium">
    <AddFile :on-close="handleOpenAddFileModal" />
  </Modal>
  <Modal :on-close="handleSendEmailClick" :open="openModalSendEmail" size="medium">
    <SendEmail :on-send="handleSendEmail" :sendEmailSuccess="sendEmailSuccess" />
  </Modal>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import IconAddFile from '@/assets/icons/addFile.svg'
import IconUnder from '@/assets/icons/under.svg'
import IconUp from '@/assets/icons/up.svg'
import IconInfo from '@/assets/icons/infoRound.svg'
import IconDownload from '@/assets/icons/download.svg'
import IconSend from '@/assets/icons/send.svg'
import IconRemove from '@/assets/icons/remove.svg'
import IconBack from '@/assets/icons/back.svg'
import interact from 'interactjs'
import { usePanelStore } from '@/stores/usePanelStore'
import ItemElement from '@/layouts/Panel/ItemElement'
import { toPng } from 'html-to-image'
import Slider from '@/layouts/Panel/Slider';
import Modal from '@/components/Modal';
import AddFile from '@/layouts/Panel/AddFile';
import InfoFile from '@/layouts/Panel/InfoFile';
import MenuContext from '@/layouts/Panel/MenuContext';
import PDF from '@/layouts/Panel/PDF';
import SendEmail from '@/layouts/Panel/SendEmail';
import { useNotificationStore } from '@/stores/useNotificationStore';
// import download from "downloadjs";

function getPostion(moveX, startX, moveY, startY) {
  const width = moveX > startX ? moveX - startX : startX - moveX
  const left = moveX > startX ? startX : moveX

  const height = moveY > startY ? moveY - startY : startY- moveY
  const top = moveY > startY ? startY : moveY
  return { width, left, height, top }
}

export default {
  name: "Panel",
  components: {
    Tooltip,
    IconAddFile,
    IconUnder,
    IconUp,
    IconInfo,
    IconDownload,
    IconSend,
    ItemElement,
    Slider,
    Modal,
    AddFile,
    InfoFile,
    MenuContext,
    PDF,
    IconRemove,
    SendEmail,
    IconBack,
  },
  setup() {
    const panelStore = usePanelStore()
    const notificationsStore = useNotificationStore()
    return { panelStore, notificationsStore }
  },
  data() {
    return {
      zoom: 1,
      translateX: 0,
      openAddFileModal: false,
      openInfoFile: false,
      drag: false,
      cursorClick: false,
      dragOptions: {
        moveX: 0,
        moveY: 0,
        startX: 0,
        startY: 0,
      },
      pdf: null,
      imageToPdf: null,
      openModalSendEmail: false,
      widthDropzone: 100,
      historyLength: this.panelStore.history.length,
      sendEmailSuccess: false,
    }
  },
  computed: {
    getWidth() {
      return `${this.widthDropzone * this.zoom}px`
    },
    getSizesDropzones() {
      return {
        width: `${2000 * this.zoom}px`,
        height: `${1500 * this.zoom}px`
      }
    },
    getDragStyle() {
      const { width, left, height, top } = getPostion(this.dragOptions.moveX, this.dragOptions.startX, this.dragOptions.moveY, this.dragOptions.startY)
      return {
        width: width + 'px',
        height: height + 'px',
        left: left + 'px',
        top: top + 'px',
      }
    }
  },
  mounted() {
    let dropzone = this.$refs.myDropzone
    this.initDropzone(dropzone)

    const dropzoneParent = this.$refs.panel
    this.widthDropzone = dropzoneParent.offsetWidth

    this.panelStore.$subscribe(() => {
      this.historyLength = this.panelStore.history.length
    })

    this.notificationsStore.$subscribe((mutation, state) => {
      if (state.sendEmailStatus) {
        this.sendEmailSuccess = true
        setTimeout(() => {
          this.sendEmailSuccess = false
          this.openModalSendEmail = false
          this.notificationsStore.setSendEmailStatus(false)
        }, 3000)
      }
    })

    this.$refs.panel.addEventListener('scroll', (e) => {
      this.panelStore.setScrollLeft(e.target.scrollLeft)
      this.panelStore.setScrollTop(e.target.scrollTop)
    })
    this.panelStore.setWidth(this.$refs.panel.offsetWidth)

    window.addEventListener('resize', () => {
      this.panelStore.setWidth(this.$refs.panel.offsetWidth)
    })

    document.addEventListener('keydown', e => {
      switch (e.key) {
        case "Down": // IE/Edge specific value
        case "ArrowDown":
          this.handleKeypressArrow(0, 5, e)
          break;
        case "Up": // IE/Edge specific value
        case "ArrowUp":
          this.handleKeypressArrow(0, -5, e)
          break;
        case "Left": // IE/Edge specific value
        case "ArrowLeft":
          this.handleKeypressArrow(-5, 0, e)
          break;
        case "Right": // IE/Edge specific value
        case "ArrowRight":
          this.handleKeypressArrow(5, 0, e)
          break;
        case "Esc": // IE/Edge specific value
        case "Escape":
          this.handleEscapeActive()
          break;
        case "Del": // IE/Edge specific value
        case "Delete":
          this.handleRemoveActive()
          break;
        case "c":
          if (e.ctrlKey || e.metaKey) {
            this.handleCopy(e)
          }
          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }
    })
  },
  methods: {
    handleCopy(e) {
      e.preventDefault()
      const activeElements = this.panelStore.getActiveElements
      let newElements = []
      activeElements.forEach(el => {
        const newItem = {...el, x: el.x + 30, y: el.y + 30, active: true}
        delete newItem.id
        newElements.push(newItem)
        this.panelStore.updateElementById(el.id, { active: false })
      })
      this.panelStore.setMoreElements(newElements)
    },
    handleKeypressArrow(x, y, e) {
      const activelements = this.panelStore.getActiveElements
      if (activelements.length > 0) {
        e.preventDefault()
        let newElements = []
        activelements.forEach(el => {
          const newItem = {
            id: el.id,
            object: { x: el.x + x, y: el.y + y }
          }
          newElements.push(newItem)
        })
        this.panelStore.updateMoreElementById(newElements)
      }
    },
    handleEscapeActive() {
      this.panelStore.getActiveElements.forEach(el => {
        this.panelStore.updateElementById(el.id, { active: false })
      })
    },
    handleRemoveActive() {
      let removeElements = []
      const activeElements = this.panelStore.getActiveElements
      activeElements.forEach(el => {
        const removeItemId = el.id
        removeElements.push(removeItemId)
      })
      this.panelStore.removeMoreElements(removeElements)
    },
    handleRemoveClick() {
      this.panelStore.removeAllElement()
    },
    handleBackClick() {
      this.panelStore.undo()
    },
    doneGeneratePdf() {
      this.pdf = null
      this.imageToPdf = null
    },
    handleMouseUp() {
      if (this.drag) {
        this.panelStore.setActiveElementsByPosition(getPostion(this.dragOptions.moveX / this.zoom, this.dragOptions.startX / this.zoom, this.dragOptions.moveY / this.zoom, this.dragOptions.startY / this.zoom))
      }

      this.drag = false
      this.cursorClick = false
      this.dragOptions = {
        moveX: 0,
        moveY: 0,
        startX: 0,
        startY: 0,
      }
    },
    handleMouseDown(e) {
      if (e.target.classList.contains('dropzone') && !this.drag) {
        this.panelStore.getActiveElements.forEach(el => {
          this.panelStore.updateElementById(el.id, { active: false })
        })
      }
      if (this.panelStore.getActiveElements.length === 0 && e.target.classList.contains('dropzone')) {
        this.cursorClick = true
        this.dragOptions.startX = e.offsetX
        this.dragOptions.startY = e.offsetY
      }
    },
    handleMouseMove(e) {
      if (this.cursorClick) {
        const moveX = e.offsetX
        const moveY = e.offsetY

        const compare = (a1, a2) => {
          return a2 === 0 || a1 > 5 && (a2 - a1 < 200 || a2 - a1 > -200)
        }

        if (compare(moveY, this.dragOptions.moveY) && compare(moveX, this.dragOptions.moveX)) {
          this.drag = true
          this.dragOptions.moveX = moveX
          this.dragOptions.moveY = moveY
        }
      }
    },
    handleOpenInfoFile() {
      this.openInfoFile = !this.openInfoFile
    },
    handleDownClick() {
      let newElements = []
      this.panelStore.getActiveElements.forEach(el => {
        const newItem = {
          id: el.id,
          object: { zindex: el.zindex - 1 }
        }
        newElements.push(newItem)
      })
      this.panelStore.updateMoreElementById(newElements)
    },
    handleUpClick() {
      let newElements = []
      this.panelStore.getActiveElements.forEach(el => {
        const newItem = {
          id: el.id,
          object: { zindex: el.zindex + 1 }
        }
        newElements.push(newItem)
      })
      this.panelStore.updateMoreElementById(newElements)
    },
    handleOpenAddFileModal() {
      this.openAddFileModal = !this.openAddFileModal
    },
    handleChangeZoom(value) {
      if (value === 'plus') {
        if (this.zoom < 2) {
          this.zoom += 0.1
        }
      } else if (value === 'minus') {
        if (this.zoom > 0.4) {
          this.zoom -= 0.1
        }
      } else {
        this.zoom = Math.floor(((((value * 100) * 1.6) / 100) + 0.4) * 10) / 10
      }
    },
    handleDownloadClick() {
      const activElements = this.panelStore.getActiveElements
      activElements.forEach(el => {
        this.panelStore.updateElementById(el.id, { active: false })
      })
      const zoom = this.zoom
      this.zoom = 1
      toPng(this.$refs.myDropzone)
      .then(dataUrl => {
        this.zoom = zoom
        // download(dataUrl)
        this.imageToPdf = dataUrl
        this.pdf = 'download'
      })
    },
    handleSendEmail(email) {
      const activElements = this.panelStore.getActiveElements
      activElements.forEach(el => {
        this.panelStore.updateElementById(el.id, { active: false })
      })
      toPng(this.$refs.myDropzone)
          .then(dataUrl => {
            this.imageToPdf = dataUrl
            this.pdf = email
          })
    },
    handleSendEmailClick() {
      this.openModalSendEmail = !this.openModalSendEmail
    },
    initDropzone: function (selector) {
      const dropzone = interact(selector)
      const panelStore = this.panelStore

      let zoom = this.zoom
      this.$watch('zoom', (newState) => {
        zoom = newState
      })

      dropzone
          .dropzone({
            overlap: 0.5,
            accept: '.product',
            ondrop: function (event) {
              const dropzonePostion = event.target.getBoundingClientRect()
              const elementPosition = event.relatedTarget.getBoundingClientRect()
              const x = elementPosition.left - dropzonePostion.left
              const y = elementPosition.top - dropzonePostion.top
              panelStore.setElement({
                element: event.draggable.options.drag.data,
                x: x / zoom,
                y: y / zoom,
              })
            },
          })
    }
  },
  watch: {
    zoom(newZoom) {
      this.panelStore.setZoom(newZoom)
    }
  }
}
</script>

<style scoped lang="scss">
.flex-grow {
  flex-grow: 1;
}
.dropzone {
  position: relative;
  flex-grow: 1;
  left: 0;
  top: 0;
  transform-origin: top left;
}
.btn {
  @include flex-center;
}
.panel {
  padding: 20px 20px;
  display: flex;
  width: 100%;
  max-width: calc(100vw - 309px);
  min-height: 646px;
  position: sticky;
  left: 0;
  top: 72px;
  max-height: calc(100vh - 82px);
  flex-wrap: wrap;
  height: fit-content;
  @media ($xlg) {
    height: 100%;
    padding: 41px 45px 41px 49px;
    max-width: calc(100vw - 409px);
  }
  &__panel {
    @include scrool-style();
    background: $white;
    border: 0.5px solid #A49A8B;
    //min-height: 564px;
    //height: max-content;
    flex-grow: 1;
    height: 100%;
    position: relative;
    overflow: auto;
    max-height: calc(100vh - 102px);
    //min-width: 600px;
    @media ($lg) {
      max-width: calc(100% - 90px);
    }
    @media ($xlg) {
      max-width: calc(100% - 80px);
    }
    &__slider {
      position: absolute;
      z-index: 30;
      //right: 10px;
      left: calc(100% - 300px);
      top: 30px;
      width: 200px;
      margin-right: 0;
      margin-left: auto;
      @media ($xlg) {
        left: calc(100% - 350px);
        top: 50px;
      }
    }
  }
  &__buttons {
    display: flex;
    padding-top: 29px;
    width: 100%;
    min-height: 380px;
    @media ($lg) {
      width: auto;
      height: calc(100vh - 172px);
      max-height: 564px;
      padding-left: 19px;
      padding-top: 0;
      flex-direction: column;
      min-height: calc(100vh - 182px);
    }
    @media ($xlg) {
      padding-left: 29px;
    }
  }
}
.button {
  @include flex-center;
  border-radius: 100%;
  border: 0.5px solid $black;
  background: $greyBack;
  width: 45px;
  height: 45px;
  transition: border-color 0.2s;
  margin-right: 6px;
  @media ($lg) {
    margin-bottom: 6px;
    margin-right: 0;
  }
  svg {
    transition: opacity 0.2s;
    fill: $black;
    transform: scale(0.9);
  }
  &:hover {
    border-color: $brown;
    svg {
      opacity: 0.5;
    }
  }
}

.selected {
  position: absolute;
  background: rgba($red, 0.2);
  border: 1px solid $red;
  display: block;
  z-index: 100;
}

.v-enter-active,
.v-leave-active {
  transition: transform 0.2s ease;
  position: absolute;
  top: 0;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(+100%);
  position: absolute;
  top: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>