<template>
<div class="wrapper">
  <div class="header">
    <h2 class="header__title">
      <span>Jak korzystać</span>
      <strong>z aranżatora płytek</strong>
    </h2>
    <nav class="header__nav">
      <ul>
        <li v-for="n in maxSite" :key="n">
          <button
              type="button"
              class="header__nav__button"
              :class="site === n && 'active'"
              @click="handleChangeSite(n)"
          >
            {{ n }}
          </button>
          <IconArrow v-if="n < maxSite" class="header__nav__arrow" />
        </li>
      </ul>
    </nav>
  </div>
  <div class="content-wrapper">
    <Transition duration="300" :name="direction">
    <div v-if="site === 1" class="content">
      <div>
        <h3 class="content__title">KROK 1 / WYBÓR PŁYTEK I ZAPEŁNIANIE OBSZARU ROBOCZEGO</h3>
        <div class="content__text">
          Wybierz interesujące Cię płytki z listy poniżej. Możesz też wpisać nazwę płytki w wyszukiwarce lub użyć filtrów. Przeciągnij swobodnie do obszaru roboczego wybraną płytkę lub w przypadku płytki, która ma wiele twarzy kliknij w “więcej produktów”. Możesz przeciągnąć wszystkie twarze klikając w przycisk: “przeciągnij wszystkie”.<br />
          W dowolnym momencie możesz usunąć całą zawartość obszaru (ikona kosza po prawej stronie obszaru) i zacząć kreować nowe zestawienia.
        </div>
      </div>
      <div>
        <img src="@/assets/info/opoczno_1-1.png" alt="KROK 1 / WYBÓR PŁYTEK I ZAPEŁNIANIE OBSZARU ROBOCZEGO">
      </div>
    </div>
    <div v-else-if="site === 2" class="content">
      <div>
        <h3 class="content__title">KROK 2 / FUNKCJE UKŁADANIA PŁYTEK</h3>
        <div class="content__text">
          Płytki w obszarze roboczym można swobodnie przenosić, kopiować, obracać lub w usuwać. Funkcje te są dostępne po kliknięciu na dowolną płytkę w obszarze roboczym. Jeśli chcesz szybko multiplikować płytkę przeciągnij ją klikając za jej dowolny narożnik. Funkcje umieszczenia płytki nad lub pod znajdują się w menu po prawej stronie obszaru roboczego.
        </div>
      </div>
      <div>
        <img src="@/assets/info/opoczno_2-1.png" alt="KROK 2 / FUNKCJE UKŁADANIA PŁYTEK">
      </div>
    </div>
    <div v-else-if="site === 3" class="content">
      <div>
        <h3 class="content__title">KROK 3 / ZAZNACZANIE FRAGMENTÓW OBSZARU ROBOCZEGO</h3>
        <div class="content__text">
          Stworzony przez siebie układ płytek możesz też skopiować i łatwo umieścić w dowolnym miejscu. Użyj do tego klawisza CTRL + lewy przycisk myszy, za pomocą którego zaznaczysz interesujące Cię płytki. Po zaznaczeniu pojawią się opcje: Kopiuj i Przenieś.<br />
          Funkcja Zoom pozwala na dokładniejszy podgląd Twojego projektu.
        </div>
      </div>
      <div>
        <img src="@/assets/info/opoczno_3-1.png" alt="KROK 3 / ZAZNACZANIE FRAGMENTÓW OBSZARU ROBOCZEGO">
      </div>
    </div>
    <div v-else-if="site === 4" class="content">
      <div>
        <h3 class="content__title">KROK 4 / DODAWANIE WŁASNYCH PLIKÓW</h3>
        <div class="content__text">
          Kliknij na ikonę obrazu po prawej stronie obszaru roboczego i dodaj plik z Twojego komputera. Obraz możesz swobodnie przenosić, zmniejszać, zwiększać, kopiować, przenosić nad i pod płytki, korzystając z tych samych funkcji co dla płytek.
        </div>
      </div>
      <div>
        <img src="@/assets/info/opoczno_4-1.png" alt="KROK 4 / DODAWANIE WŁASNYCH PLIKÓW">
      </div>
    </div>
    <div v-else-if="site === 5" class="content">
      <div>
        <h3 class="content__title">KROK 5/ ZAPISYWANIE KOMPOZYCJI</h3>
        <div class="content__text">
          Stworzony przez siebie projekt możesz łatwo zapisać i/lub wysłać sobie na maila, korzystając z menu po prawej stronie obszaru roboczego. Aranżator automatycznie wygeneruje też listę użytych płytek, byś mógł łatwiej je potem zamówić. Podgląd użytych płytek znajdziesz też zawsze po prawej stronie (Ikona: Informacje).
        </div>
      </div>
      <div>
        <img src="@/assets/info/opoczno_5-1.png" alt="KROK 5 / ZAPISYWANIE KOMPOZYCJI">
      </div>
    </div>
  </Transition>
  </div>
  <div class="footer">
    <PrevButton text="wróć" :on-click="handleClickPrev" :disabled="site <= 1" />
    <NextButton text="dalej" :on-click="handleClickNext" :disabled="site >= maxSite" />
  </div>
</div>
</template>

<script>
import IconArrow from '@/assets/icons/arrow.svg'
import PrevButton from '@/components/PrevButton';
import NextButton from '@/components/NextButton';

export default {
  name: "InfoModal",
  components: {
    IconArrow,
    PrevButton,
    NextButton
  },
  setup() {
    return {
      maxSite: 5,
    }
  },
  data() {
    return {
      site: 1,
      direction: 'next',
    }
  },
  methods: {
    handleChangeSite(value) {
      if (value > this.site) {
        this.direction = 'next'
      } else {
        this.direction = 'prev'
      }
      this.site = value
    },
    handleClickPrev() {
      this.direction = 'prev'
      this.site = this.site - 1
    },
    handleClickNext() {
      this.direction = 'next'
      this.site = this.site + 1
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  padding: 61px 43px;
}
.header {
  @include flex-between;
  margin-bottom: 67px;
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 60px;
    font-weight: 700;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 48px;
      height: 1px;
      background: $brown;
      left: 0;
      top: rem(8px);
    }
    span {
      color: $brown;
      font-size: rem(16px);
      line-height: rem(18px);
      letter-spacing: 4px;
      text-transform: uppercase;
    }
    strong {
      color: $black;
      font-size: rem(28px);
      line-height: rem(31px);
      text-transform: lowercase;
    }
  }
  &__nav {
    ul {
      @include flex-end;
    }
    li {
      @include flex-center;
      margin-left: 20px;
      position: relative;
    }
    &__arrow {
      margin-left: 20px;
    }
    &__button {
      @include flex-center;
      width: 51px;
      height: 51px;
      border-radius: 100%;
      border: 1px solid $black;
      font-weight: 500;
      font-size: rem(24px);
      font-variant: small-caps;
      transition: all 0.2s;
      &.active, &:hover {
        border-color: $brown;
        color: $brown;
      }
    }
  }
}
.footer {
  @include flex-start;
  margin-top: 85px;
  gap: 31px;
}
.content-wrapper {
  position: relative;
  overflow-x: hidden;
  min-height: 330px;
}
.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
  top: 0;
  position: relative;
  &__title {
    font-weight: 700;
    font-size: rem(18px);
    line-height: rem(26px);
    text-transform: uppercase;
    margin-bottom: 35px;
  }
  &__text {
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(27px);
    color: rgba($black, 0.7);
  }
  img {
    width: 100%;
  }
}
.next-enter-active,
.next-leave-active,
.prev-enter-active,
.prev-leave-active {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.next-leave-to {
  transform: translateX(-100%);
  position: absolute;
  top: 0;
}
.next-enter-from {
  transform: translateX(+100%);
  position: absolute;
  top: 0;
}
.prev-leave-to {
  transform: translateX(+100%);
  position: absolute;
  top: 0;
}
.prev-enter-from {
  transform: translateX(-100%);
  position: absolute;
  top: 0;
}
</style>