export const filters = [
  {
    key: 'type',
    name: 'Rodzaj płaytki',
    columns: 2,
    filters: [
      { id: 1, name: 'Płytki ścienne' },
      { id: 2, name: 'Płytki ścienno-podłogowe' },
      { id: 3, name: 'Płytki podłogowe' },
      { id: 4, name: 'Płytki na schody' },
      { id: 5, name: 'Listwy' },
      { id: 6, name: 'Dekoracje' },
      { id: 7, name: 'Mozaika' },
    ]
  },
  {
    key: 'inspirations',
    name: 'Inspiracje',
    columns: 3,
    filters: [
      { id: 1, name: 'kamień' },
      { id: 2, name: 'beton' },
      { id: 3, name: 'marmur' },
      { id: 4, name: 'monokolor' },
      { id: 5, name: 'motyw' },
    ]
  },
  {
    key: 'color',
    name: 'Kolor',
    columns: 3,
    filters: [
      { id: 1, name: 'Beżowy' },
      { id: 2, name: 'Biały' },
      { id: 3, name: 'Brązowy' },
      { id: 4, name: 'Czarny' },
      { id: 5, name: 'Grafitowy' },
    ]
  },
  {
    key: 'finish',
    name: 'Wykończenie',
    columns: 2,
    filters: [
      { id: 1, name: 'Błyszcząca' },
      { id: 2, name: 'Matowa' },
      { id: 3, name: 'Satynowa' },
      { id: 4, name: 'Lappato' },
    ]
  },
  {
    key: 'dimension',
    name: 'Wymiar',
    columns: 2,
    filters: [
      { id: 1, name: 'Mały (do 45x45)' },
      { id: 2, name: 'Średni (do 60x60)' },
      { id: 3, name: 'Duży (powyżej 60x60)' },
      { id: 4, name: 'Bardzo duży (powyżej 80x80)' },
    ]
  },
]