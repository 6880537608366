<template>
  <div class="wrapper">
    <Transition name="fade">
      <div class="products" :class="face && 'products__with-faces'">
        <SectionTitle title="Znaleziono" light />
        <div class="products__count">
          {{ getCount }}
        </div>
        <div class="products__info">
          Przeciągnij płytkę<br /> na obszar roboczy
        </div>
        <div class="products__list">
          <ProductItem
              v-for="(item, index) in products"
              :key="item.id"
              :item="item"
              @click="handleFaceOpen(item)"
              :index="index"
              :face="false"
          />
        </div>
        <div class="products__show-more" v-if="maxPages > page">
          <button type="button" @click="page++">
            <span>pokaż więcej</span>
          </button>
        </div>
      </div>
    </Transition>

    <Transition>
      <Faces :item="face" v-if="face" :onClose="handleFaceClose" />
    </Transition>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle';
import { getQuantityType } from '@/helpers/getQuantityType';
import ProductItem from '@/layouts/Aside/ProductItem';
import Faces from '@/layouts/Aside/Faces';
import { HTTP, baseUrl } from '@/helpers/http-common';
import { useFilterStore } from '@/stores/useFilterStore'

const quantityWord = [
    'Produkt',
    'Produkty',
    'Produktów'
]

export default {
  name: "Products",
  components: {
    SectionTitle,
    ProductItem,
    Faces,
  },
  setup() {
    const filterStore = useFilterStore()
    return { filterStore }
  },
  data() {
    return {
      count: 60,
      maxPages: 30,
      page: 1,
      products: [],
      face: false,
    }
  },
  mounted() {
    this.getProducts()

    this.filterStore.$subscribe(() => {
      this.page = 1
      this.getProducts()
    })
  },
  computed: {
    getCount() {
      return `${this.count} ${quantityWord[getQuantityType(this.count)]} ${this.count === 0 ? ' - Wybierz inne filtry' : ''}`
    }
  },
  methods: {
    getProducts() {
      let filters = []
      Object.keys(this.filterStore.getFilters).forEach(el => {
        filters = [...filters, this.filterStore.getFiltersByKey(el)]
      })
      HTTP({
        method: 'get',
        url: '/searchproducts',
        headers: {
          accept: 'application/json'
        },
        params: {
          page: this.page,
          word: this.filterStore.getSearch,
          attr: filters.join(',')
        }
      })
          .then(resp => {
            const products = resp.data.products.map(el => {

              const sizes = el.realSize ? el.realSize.split('x') : el.size.split('x')

              const size1 = parseFloat(sizes[0]) * 10
              const size2 = parseFloat(sizes[1]) * 10


              const product = {
                id: el.uuid,
                name: el.translations.pl.title,
                size: el.size,
                custom: false,
                width: size1 > size2 ? size1 : size2,
                height: size1 > size2 ? size2 : size1,
                link: el.link,
                code: el.code
              }

              const faces = {
                faces: el.thumbs.length === 1 ? false : el.thumbs.map(t => {
                  return {
                    ...product,
                    img: `${baseUrl}${t.path}`,
                  }
                })
              }

              return {
                ...product,
                img: `${baseUrl}${el.thumbs[0].path}`,
                ...faces,
              }
            })
            if (resp.data.page === 1) {
              this.products = products
            } else {
              this.products = [...this.products, ...products]
            }
            this.face = false
            this.count = resp.data.maxItems
            this.maxPages = resp.data.maxPages
          })
    },
    handleFaceOpen(item) {
      if (item.faces) {
        this.face = item
      }
    },
    handleFaceClose() {
      this.face = false
    }
  },
  watch: {
    page(newPage) {
      if (newPage > 1) {
        this.getProducts()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
}
.products {
  padding: 20px 31px 20px 31px;
  &__with-faces {
    opacity: 0.4;
    pointer-events: none;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  &__count {
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(32px);
    letter-spacing: 0.05em;
    margin-bottom: 13px;
  }
  &__info {
    font-weight: 200;
    font-size: rem(16px);
    line-height: rem(22px);
    text-align: center;
    margin-bottom: 13px;
  }
  &__show-more {
    @include flex-center;
    margin-top: 27px;
    button {
      @include flex-center;
      background: $white;
      border: 0.5px solid $black;
      height: rem(46px);
      border-radius: rem(23px);
      width: 100%;
      max-width: 260px;
      font-size: rem(14px);
      letter-spacing: 0.05em;
      transition: color 0.2s, background-color 0.2s;
      &:hover {
        background: $black;
        color: $white;
      }
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: transform 0.2s ease;
  //position: absolute;
  //top: 0;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(-100%);
  //position: absolute;
  //top: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>