<template>
<div class="product" :class="item.faces ? 'faces' : ''" :draggable="!item.faces" ref="maDraggable">
  <div class="product__img">
    <img :src="item.img" :alt="item.name">
  </div>
  <div class="product__title">
    <template v-if="face">
      {{ index + 1 }}.
    </template>
    <template v-else>
      {{ item.name }}
    </template>
  </div>
  <div class="product__size" v-if="!face">
    {{ item.size }}
  </div>
  <div class="face" v-if="item.faces">
    <div class="face__round">
      Rozwiń
    </div>
  </div>
</div>
</template>

<script>
import interact from 'interactjs'
export default {
  name: "ProductItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: Number,
    face: {
      type: Boolean,
      default: false,
    },
    changeOverflow: {
      type: Function,
      required: false,
    }
  },
  mounted() {
    let draggable = this.$refs.maDraggable
    if (!this.item.faces) {
      this.initDraggable(draggable, this.changeOverflow)
    }
  },
  methods: {
    initDraggable: function (selector, changeOverflow) {
      const position = { x: 0, y: 0 }
      const draggable = interact(selector)
      draggable.draggable({
        data: this.item,
        listeners: {
          start () {
            if (changeOverflow) {
              changeOverflow('visible')
            }
          },
          move (event) {
            position.x += event.dx
            position.y += event.dy

            event.target.style.transform =
                `translate(${position.x}px, ${position.y}px)`
          },
          end (event) {
            if (changeOverflow) {
              changeOverflow('auto')
            }
            // console.log(event.type, event.target)
            position.x = 0
            position.y = 0

            event.target.style.transform =
                `translate(${position.x}px, ${position.y}px)`
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.product {
  display: block;
  border: 1px solid transparent;
  position: relative;
  transition: border-color 0.2s;
  z-index: 10;
  &:not(.faces):hover {
    border: 1px solid $red;
  }
  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(210, 203, 190, 0.9);
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &__round {
      @include flex-center;
      position: absolute;
      width: 84px;
      height: 84px;
      border-radius: 100%;
      background: $white;
      text-align: center;
      font-size: rem(10px);
      letter-spacing: 0.05em;
      text-transform: uppercase;
      white-space: break-spaces;
      line-height: rem(14px);
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.faces:hover {

  }
  &__img {
    @include flex-center;
    width: 100%;
    height: 110px;
    margin-bottom: 4px;
    img {
      pointer-events: none;
      max-width: 110px;
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title {
    font-size: rem(12px);
    font-weight: 700;
    line-height: rem(16px);
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
  }
  &__size {
    font-weight: 200;
    font-size: rem(12px);
    line-height: rem(22px);
    text-align: center;
  }
}
</style>