import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export const useFilterStore = defineStore('filters', {
  state: () => {
    return {
      search: useSessionStorage('search', ''),
      filters: useSessionStorage('filters', {}),
      filtersDef: useSessionStorage('filtersDef', [])
    }
  },
  getters: {
    getSearch(state) {
      return state.search
    },
    getFiltersByKey(state) {
      return (key) => state?.filters[key]
    },
    getFilters(state) {
      return state.filters
    },
    getFiltersDef(state) {
      return state.filtersDef
    }
  },
  actions: {
    setFiltersDef(data) {
      this.filtersDef = data
    },
    setSearch(text) {
      this.search = text
    },
    resetFilters() {
      this.search = ''
      this.filters = {}
    },
    setFilters(data, key) {
      this.filters = {...this.filters, [key]: data}
    },
    removeItemFilter(key) {
      // const newArrayFilter = this.filters[key].filter(item => item !== id)
      delete this.filters[key]
      // this.filters = {...this.filters, [key]: newArrayFilter}
    }
  }
})