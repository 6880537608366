<template>
  <button @click="handleOpenChange" class="open" :class="open && 'open--active'">
    <span>{{ open ? 'Zwiń filtry' : 'Rozwiń filtry' }}</span>
    <IconArrowDown />
  </button>
  <Transition>
    <div class="filters" v-if="open">
      <SectionTitle title="Filtruj przez" light />
      <template  v-for="item in filterStore.getFiltersDef">
        <FiltersItem
            v-if="item.attributeitem.length > 0 && [1, 2, 3, 4, 5, 6].includes(item.importId)"
            :key="item.uuid"
            :item="item"
        />
      </template>
    </div>
  </Transition>
</template>

<script>
import SectionTitle from '@/components/SectionTitle';
import { filters } from '@/schema/filters';
import FiltersItem from '@/layouts/Aside/FiltersItem';
import { HTTP } from '@/helpers/http-common';
import { useFilterStore } from '@/stores/useFilterStore'
import IconArrowDown from '@/assets/icons/arrowDown.svg'



export default {
  name: "Filters",
  components: {
    SectionTitle,
    FiltersItem,
    IconArrowDown
  },
  setup() {
    const filterStore = useFilterStore()
    return { filterStore }
  },
  data() {
    return {
      open: false,
    }
  },
  mounted() {
    if (this.filterStore.getFiltersDef.length === 0) {
      HTTP({
        method: 'get',
        url: '/attributes?stat=1',
        headers: {
          accept: 'application/json'
        }
      })
          .then(resp => {
            this.filterStore.setFiltersDef(resp.data)
          })
    }

  },
  computed: {
    filtersList() {
      return filters
    }
  },
  methods: {
    handleOpenChange() {
      this.open = !this.open
    }
  }
}
</script>

<style scoped lang="scss">
.open {
  @include flex-center;
  width: calc(100% - 62px);
  height: 46px;
  margin: 20px 31px 20px 31px;
  padding: 5px 23px;
  background-color: $filtersButton;
  span {
    font-weight: 700;
    font-size: rem(12px);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $black;
    flex-grow: 1;
  }
  svg {
    transition: all 0.2s;
  }
  &--active {
    svg {
      transform: rotate(180deg);
    }
  }
}
.filters {
  padding: 20px 31px 20px 31px;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  max-height: 500px;
}

.v-enter-to {
  max-height: 500px;
}

.v-enter-from,
.v-leave-to {
  max-height: 0;
  padding: 0 31px;
}
</style>