<template>
  <div class="search">
    <SectionTitle title="Szukaj" class="search__title" />
    <div class="search__input">
      <input
          v-model="query"
          placeholder="Wpisz szukaną nazwę płytki"
          @keydown="handleEnter"
      />
      <button type="button" @click="handleButtonClick">
        <IconSearch />
      </button>
    </div>
<!--    <p>-->
<!--      {{ filterStore.getSearch }}-->
<!--    </p>-->
<!--    <input type="text" v-on:change="handleSearch">-->
  </div>
  <div class="response" :class="computedList.length > 0 && 'active'">
    <TransitionGroup
        tag="ul"
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
    >
      <li
          v-for="(item, index) in computedList"
          :key="item.id"
          :data-index="index"
          class="response__item"
          @click="handleItemClick(item)"
          tabindex="0"
          @keydown="(e) => handleEnterItem(e, item)"
      >
        <span>
        {{ item.title }}
        </span>
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
import { useFilterStore } from '@/stores/useFilterStore'
import SectionTitle from '@/components/SectionTitle';
import gsap from 'gsap'
import IconSearch from '@/assets/icons/search.svg'
import { HTTP } from '@/helpers/http-common';

function uniqBy(a, key) {
  let seen = new Set();
  return a.filter(item => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export default {
  name: "Search",
  components: {
    SectionTitle,
    IconSearch,
  },
  setup() {
    const filterStore = useFilterStore()
    return { filterStore }
  },
  data() {
    return {
      query: '',
      list: [],
    }
  },
  computed: {
    computedList() {
      if (this.query.length < 3) {
        return []
      }
      return uniqBy(this.list, key => key.title)
    }
  },
  methods: {
    handleEnter(e) {
      if (e.code === 'Enter') {
        this.filterStore.setSearch(this.query)
      }
    },
    handleButtonClick() {
      this.filterStore.setSearch(this.query)
    },
    handleItemClick(item) {
      this.filterStore.setSearch(item.title)
      this.query = ''
    },
    handleEnterItem(e, item) {
      if (e.code === 'Enter') {
        this.filterStore.setSearch(item.title)
        this.query = ''
      }
    },
    handleSearch(e) {
      this.filterStore.setSearch(e.target.value)
    },
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        height: '37px',
        delay: el.dataset.index * 0.1,
        onComplete: done
      })
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        delay: el.dataset.index * 0.1,
        onComplete: done
      })
    }
  },
  watch: {
    query(newQuery) {
      // console.log(newQuery)
      if (newQuery.length > 2) {
        HTTP({
          method: 'get',
          url: '/searchproducts',
          headers: {
            accept: 'application/json'
          },
          params: {
            page: 1,
            perPage: 20,
            word: newQuery,
            thumbs: 'off',
            pagination: 'off',
          }
        })
            .then(resp => {
              let list = []
              resp.data.products.forEach((el, index) => {
                list = [...list, { id: index, title: el.translations.pl.title }]
              })
              this.list = list
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  background: $greySearch;
  padding: 26px 31px 28px 31px;
  &__title {
    padding-left: 12px;
  }
  &__input {
    @include flex-start;
    width: 100%;
    margin-top: 20px;
    button {
      @include flex-center;
      background: $searchButton;
      height: 46px;
      width: 66px;
      transition: background-color 0.2s;
      &:hover {
        background: $brown;
      }
    }
    input {
      height: 46px;
      width: calc(100% - 66px);
      padding-left: 14px;
      font-size: rem(12px);
    }
  }
}
.response {
  background: $greySearch;
  width: 100%;
  position: absolute;
  padding: 0 31px 0 31px;
  transition: padding-bottom 0.2s;
  z-index: 11;
  &.active {
    padding: 0 31px 28px 31px;
  }
  &__item {
    font-size: rem(14px);
    line-height: rem(32px);
    border-bottom: 0.6px solid $grey;
    font-weight: 300;
    padding-top: 5px;
    cursor: pointer;
    strong {
      font-weight: 400;
    }
    span {
      @include limit-line-count(1);
    }
  }
}
</style>