<template>
  <header class="header">
    <span>WWYŚLIJ E-MAIL Z PROJEKTEM I ZESTAWIENIEM PŁYTEK</span>
  </header>
  <div v-if="sendEmailSuccess" class="email-wrapper">
    <div class="email-wrapper__info">
      Email został wysłany
    </div>
  </div>
  <div v-else class="email-wrapper">
    <label for="email">Podaj swój e-mail</label>
    <input
        :aria-invalid="error"
        type="email"
        placeholder="np. jan@example.com"
        id="email"
        v-model="email"
    >
    <div class="error" v-if="error">Wprowadź prawidłowy adres email</div>
    <button class="global-button" @click="handleClickSend">
      <span>Wyślij</span>
    </button>
  </div>
</template>

<script>
const validateEmail = (data) => {
  // eslint-disable-next-line no-param-reassign, no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(data)
}

export default {
  name: "SendEmail",
  data() {
    return {
      email: '',
      error: false,
    }
  },
  props: {
    onSend: Function,
    sendEmailSuccess: Boolean,
  },
  methods: {
    handleClickSend() {
      if (validateEmail(this.email)) {
        this.error = false
        this.onSend(this.email)
      } else {
        this.error = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  @include flex-start;
  height: 61px;
  padding-left: 34px;
  background: $greyBack;
  border-bottom: 0.5px solid $grey;
  span {
    font-size: rem(12px);
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 700;
    color: $brown;
  }
}
.email-wrapper {
  @include flex-center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 350px;
  label {
    font-weight: 700;
    font-size: rem(12px);
    line-height: rem(13.5px);
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $titleColor;
    width: 300px;
    margin-bottom: 30px;
  }
  input {
    height: 46px;
    width: 300px;
    padding-left: 14px;
    font-size: rem(12px);
    border: 1px solid $grey;
    margin-bottom: 30px;
    &[aria-invalid="true"] {
      border-color: $red;
    }
  }
  .error {
    font-size: rem(12px);
    color: $red;
    position: absolute;
    width: 300px;
    margin-top: rem(30px);
  }
  &__info {
    font-weight: 700;
    text-align: center;
    font-size: rem(16px);
    line-height: rem(20.5px);
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $titleColor;
    width: 100%;
  }
}
</style>