<template>
<div class="info-file">
  <header class="info-file__header">
    <h2>Wybrane płytki</h2>
    <Tooltip helper-text="pobierz listę" site="bottom" :on-click="onDownload">
      <span class="info-file__header__btn">
        <IconDownload />
      </span>
    </Tooltip>
    <Tooltip helper-text="wyślij na maila" site="bottom" :on-click="onSendemail">
      <span class="info-file__header__btn">
        <IconSend />
      </span>
    </Tooltip>
    <Tooltip helper-text="zamknij" site="bottom" :on-click="onClose">
      <span class="info-file__header__btn info-file__header__btn--no-border">
        <IconClose />
      </span>
    </Tooltip>
  </header>
  <div class="info-file__list" >
    <div ref="myList">
      <UsedElement v-for="item in panelStore.getUsedElements" :item="item" :key="item.id" />
    </div>
  </div>
</div>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import IconDownload from '@/assets/icons/download.svg'
import IconSend from '@/assets/icons/send.svg'
import IconClose from '@/assets/icons/close.svg'
import { usePanelStore } from '@/stores/usePanelStore'
import UsedElement from '@/layouts/Panel/UsedElement';

export default {
  name: "InfoFile",
  components: {
    Tooltip,
    IconSend,
    IconDownload,
    IconClose,
    UsedElement,
  },
  props: {
    onClose: Function,
    onDownload: Function,
    onSendemail: Function,
  },
  setup() {
    const panelStore = usePanelStore()
    return { panelStore }
  },
}
</script>

<style scoped lang="scss">
.info-file {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 457px;
  height: 100%;
  background: $white;
  z-index: 100;
  border-left: 0.5px solid $brown;
  overflow: hidden;
  &__header {
    @include flex-start;
    height: 62px;
    padding-left: 34px;
    padding-right: 16px;
    background: $greyBack;
    border-bottom: 0.5px solid $grey;
    h2 {
      font-size: rem(12px);
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 700;
      color: $brown;
      flex-grow: 1;
    }
    &__btn {
      @include flex-center;
      border-radius: 100%;
      border: 0.5px solid $black;
      background: $greyBack;
      width: 42px;
      height: 42px;
      transition: border-color 0.2s;
      margin-right: 5px;
      &--no-border {
        border: 0;
        margin-right: 0;
      }
      svg {
        transition: opacity 0.2s;
      }
      &:hover {
        border-color: $brown;
        svg {
          opacity: 0.5;
        }
      }
    }
  }
  &__list {
    @include scrool-style();
    overflow: auto;
    height: calc(100% - 62px);
    padding: 0 27px 0 30px;
  }
}
</style>