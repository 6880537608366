<template>
  <h3 :class="{
    light: light,
    disableTextTransform: disableTextTransform
  }">
    <span>
    {{ title }}
    </span>
  </h3>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: String,
    light: Boolean,
    disableTextTransform: Boolean,
  }
}
</script>

<style scoped lang="scss">
  h3 {
    @include flex-start;
    position: relative;
    span {
      font-weight: 700;
      font-size: rem(12px);
      line-height: rem(13.5px);
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $titleColor;
    }
    &:after {
      content: '';
      display: block;
      width: 48px;
      height: 1px;
      background: $titleColor;
      margin-left: 14px;
    }
    &.light {
      span {
        color: $brown;
      }
      &:after {
        background: $brown;
      }
    }
    &.disableTextTransform {
      justify-content: center;
      span {
        text-transform: none;
        font-weight: 400;
        letter-spacing: 1px;
      }
      &:after {
        display: none;
      }
    }
  }
</style>