import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { getSizeFromMm } from '@/helpers/getSizeFromMm';

export const usePanelStore = defineStore('panel', {
  state: () => {
    return {
      elements: useSessionStorage('elements', []),
      menuText: 'przenieś element',
      zoom: 1,
      scrollTop: 0,
      scrollLeft: 0,
      width: 0,
    }
  },
  history: {
    name: 'elements',
    count: 10,
    whichActions: {
      'setElement': {},
      'setMoreElements': {},
      'removeElement': {},
      'removeMoreElements': {},
      'removeAllElement': {},
      'updateMoreElementById': {},
      'updateElementById': {
        disableArgs: {1: 'active'}
      }
    }
  },
  getters: {
    getZoom(state) {
      return state.zoom
    },
    getWidth(state) {
      return state.width
    },
    getScrollTop(state) {
      return state.scrollTop
    },
    getScrollLeft(state) {
      return state.scrollLeft
    },
    getElements(state) {
      return state.elements
    },
    getActiveElements(state) {
      return state.elements.filter(el => el.active)
    },
    getUsedElements(state) {
      const data = state.elements.map(el => el.element)
      const onlyStores = data.filter(el => !el.custom)
      return onlyStores.filter((element, index) => {
        return onlyStores.findIndex(x => x.id === element.id) === index
      })
    },
    getMenuText(state) {
      return state.menuText
    },
    getHistoryElements(state) {
      return state.historyElements
    }
  },
  actions: {
    setZoom(number) {
      this.zoom = number
    },
    setWidth(number) {
      this.width = number
    },
    setScrollTop(number) {
      this.scrollTop = number
    },
    setScrollLeft(number) {
      this.scrollLeft = number
    },
    setMenuText(text) {
      this.menuText = text
    },
    setElement(element) {
      const lastElement = this.elements[this.elements.length - 1]
      let id = lastElement ? lastElement.id + 1 : 1
      this.elements = [...this.elements, { ...element, id, active: element.active || false, zindex: element.zindex || 10, rotate: element.rotate || 0, }]
    },
    setMoreElements(array) {
      const lastElement = this.elements[this.elements.length - 1]
      array.forEach((el, i) => {
        let id = lastElement ? lastElement.id + i + 1 : 1 + i
        this.elements = [...this.elements, { ...el, id, active: el.active || false, zindex: el.zindex || 10, rotate: el.rotate || 0, }]
      })
    },
    setActiveElementsByPosition(position) {
      this.elements.forEach(el => {
        const width = getSizeFromMm((el.rotate / 90) % 2 ? el.element.height : el.element.width)
        const height = getSizeFromMm((el.rotate / 90) % 2 ? el.element.width : el.element.height)

        if (
          (el.x > position.left || el.x + width > position.left)
          && (el.x < position.left + position.width || el.x + width < position.left + position.width)
          && (el.y > position.top || el.y + height > position.top)
          && (el.y < position.top + position.height || el.y + height < position.top + position.height)
        ) {
          this.updateElementById(el.id, {active: true})
        }
      })
    },
    updateElement(element) {
      const foundIndex = this.elements.findIndex(x => x.id === element.id)
      if (foundIndex > -1) {
        this.elements[foundIndex] = element
      }
    },
    updateElementById(id, object) {
      const foundIndex = this.elements.findIndex(x => x.id === id)
      if (foundIndex > -1) {
        this.elements[foundIndex] = {...this.elements[foundIndex], ...object}
      }
    },
    updateMoreElementById(array) {
      array.forEach(el => {
        const {id, object} = el
        const foundIndex = this.elements.findIndex(x => x.id === id)
        if (foundIndex > -1) {
          this.elements[foundIndex] = {...this.elements[foundIndex], ...object}
        }
      })
    },
    updateElementWithoutHistoryById(id, object) {
      const foundIndex = this.elements.findIndex(x => x.id === id)
      if (foundIndex > -1) {
        this.elements[foundIndex] = {...this.elements[foundIndex], ...object}
      }
    },
    removeElement(id) {
      const foundIndex = this.elements.findIndex(x => x.id === id)
      if (foundIndex > -1) {
        this.elements.splice(foundIndex, 1)
      }
    },
    removeMoreElements(array) {
      array.forEach(id => {
        const foundIndex = this.elements.findIndex(x => x.id === id)
        if (foundIndex > -1) {
          this.elements.splice(foundIndex, 1)
        }
      })
    },
    removeAllElement() {
      this.elements = []
    }
  }
})