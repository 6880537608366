<template>
  <header class="header">
    <a href="https://www.opoczno.eu/" target="_blank">
      <img alt="Opoczno logo" class="logo" src="@/assets/logo.png">
    </a>
    <h1 class="title">Aranżer płytek</h1>
    <Tooltip helper-text="pomoc" :on-click="handleClick" class="helper-button">
      <span class="info-button">
        <IconInfo />
      </span>
    </Tooltip>
    <Modal :open="openModal" :on-close="handleClick">
      <InfoModal />
    </Modal>
  </header>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import Modal from '@/components/Modal';
import InfoModal from '@/layouts/AppBar/InfoModal';
import IconInfo from '@/assets/icons/info.svg'

export default {
  name: "AppBar",
  components: {
    Tooltip,
    Modal,
    InfoModal,
    IconInfo
  },
  data() {
    return {
      openModal: false,
    }
  },
  methods: {
    handleClick() {
      this.openModal = !this.openModal
    }
  }
}
</script>

<style scoped lang="scss">
  .header {
    @include flex-start;
    background: $white;
    box-shadow: 0 1px 4px rgba(48, 49, 53, 0.25);
    width: 100%;
    position: fixed;
    z-index: 15;
    padding-right: 47px;
    height: 72px;
    top: 0;
  }
  .logo {
    height: 72px;
  }
  .title {
    font-size: rem(22px);
    padding-left: 24px;
    flex-grow: 1;
  }
  .info-button {
    @include flex-center;
    background: $brown;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    transition: background-color 0.2s;
  }
  .helper-button {
    &:hover, &:focus {
      .info-button {
        background: $black;
      }
    }
  }
</style>