<template>
<div class="used-element">
  <div class="used-element__info">
    <img :src="item.img" :alt="item.name">
    <div class="used-element__info__text">
      <h3>{{ item.name }}</h3>
      <span>{{ item.size }}</span>
    </div>
  </div>
  <div class="used-element__button">
    <a class="global-button" :href="item.link" target="_blank">przejdź do karty produktu</a>
  </div>
</div>
</template>

<script>
export default {
  name: "UsedElement",
  props: {
    item: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style scoped lang="scss">
.used-element {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 0.5px solid $grey;
  &__info {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 18px;
    margin-bottom: 20px;
    img {
      display: block;
      width: 100%;
    }
    &__text {
      @include flex-between;
      flex-direction: column;
      align-items: flex-start;
      h3 {
        font-weight: 700;
        font-size: rem(16px);
        line-height: rem(21px);
        text-transform: uppercase;
      }
      span {
        font-weight: 200;
        font-size: rem(14px);
        line-height: rem(16px);
      }
    }
  }
}
</style>