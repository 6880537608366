
export const piniaUndoPlugin = ({ store, options }) => {
  let history = []
  if (process.env.NODE_ENV === 'development') {
    store._customProperties.add('history')
  }
  let undo = () => null
  if (options.history) {
    if (store.history === undefined) {
      history.unshift({
        type: 'init',
        value: JSON.stringify(store[options.history.name])
      })
    }
    store.$onAction((st) => {
      if (Object.keys(options.history.whichActions).includes(st.name)) {
        let enable = true
        if (options.history.whichActions[st.name].disableArgs) {
          for (let i in options.history.whichActions[st.name].disableArgs) {
            if (Object.hasOwn(st.args[i], options.history.whichActions[st.name].disableArgs[i])) {
              enable = false
            }
          }
        }
        if (enable) {
          st.after(() => {
            const newData = st.store[options.history.name]
            store.history.unshift({
              type: st.name,
              value: JSON.stringify(newData)
            })
          })
        }
        if (store.history.length > options.history.count) {
          store.history.pop()
        }
      }
    })

    undo = () => {
      if (store.history.length > 1) {
        store.history.shift()
        store.$state[options.history.name] = JSON.parse(store.history[0].value)
        store[options.history.name] = JSON.parse(store.history[0].value)
      }
    }

    return {
      history, undo,
    }
  }
  return null
}