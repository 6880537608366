<template>
    <div class="slider">
      <button type="button" @click="onChange('minus')">
        <IconMinus />
      </button>
      <div class="slider__slider">
        <div ref="mySlider" class="drop-slider" :style="{
          paddingLeft: `${((zoom - 0.4) * 100) / 1.6}%`
        }"></div>
      </div>
      <button type="button">
        <IconPlus @click="onChange('plus')" />
      </button>
    </div>
</template>

<script>
import interact from 'interactjs';
import IconMinus from '@/assets/icons/minus.svg'
import IconPlus from '@/assets/icons/plus.svg'

export default {
  name: "Slider",
  components: {
    IconMinus,
    IconPlus
  },
  props: {
    onChange: Function,
    zoom: Number,
  },
  mounted() {
    const slider = this.$refs.mySlider
    this.initSlider(slider)
  },
  methods: {
    initSlider(selector) {
      const interactInit = interact(selector)
      const onChange = this.onChange

      interactInit.draggable({
        origin: 'self',
        inertia: true,
        modifiers: [
          interact.modifiers.restrict({
            restriction: 'self'
          })
        ],
        listeners: {
          move (event) {
            const sliderWidth = interact.getElementRect(event.target).width
            const value = event.pageX / sliderWidth

            onChange(value)

            // event.target.style.paddingLeft = (value * 100) + '%'
            // event.target.setAttribute('data-value', value.toFixed(2))
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.slider {
  @include flex-center;
  width: 200px;
  position: relative;
  button {
    @include flex-center;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }
  &__slider {
    width: calc(100% - 120px);
    position: relative;
  }
}
.drop-slider {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 8px auto;
  background-color: $black;
  box-sizing: border-box;
  font-size: 1em;
  -ms-touch-action: none;
  touch-action: none;
  &:before {
    content: "";
    display: block;
    position: relative;
    top: -0.5em;

    width: 16px;
    height: 16px;
    margin-left: -8px;
    border-radius: 100%;
    background: $brown;

    box-sizing: border-box;
  }
}
</style>