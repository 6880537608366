<template>
<Teleport to="body">
  <Transition>
    <div
        class="window-back"
        v-if="open"
        @click="onClose"
    ></div>
  </Transition>
  <Transition>
    <div v-if="open" class="modal" :class="size">
      <button type="button" ref="close" @click="onClose" class="modal__close" aria-label="Zamknij okno">
        <IconClose />
      </button>
      <div class="modal__content">
        <slot></slot>
      </div>
    </div>
  </Transition>

</Teleport>
</template>

<script>
import IconClose from '@/assets/icons/close.svg'
export default {
  name: "Modal",
  components: {
    IconClose
  },
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['large', 'medium',].includes(value)
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.code === 'Escape' && this.open) {
        this.onClose()
      }
    })
  }
}
</script>

<style scoped lang="scss">
  .modal {
    width: 100%;
    min-height: 430px;
    position: fixed;
    max-width: 1040px;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: $white;
    &.medium {
      max-width: 700px;
    }
    &__close {
      position: absolute;
      top: 18px;
      right: 18px;
      z-index: 10;
    }
    &__content {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .window-back {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    background-color: rgba(48, 49, 53, 0.3);
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.4s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>