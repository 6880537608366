<template>
<div class="faces" :style="faceStyle">
  <PrevButton text="powrót" class="faces__prev" :on-click="onClose" />
  <SectionTitle title="Możliwe wzory płytki w ramach kolekcji" light disableTextTransform />
  <div class="faces__title">{{ item.name }}</div>
  <div class="faces__info">
    Przeciągnij zróżnicowane wzory płytki<br />
    które występują w ramach kolekcji<br />
    i uzyskaj naturalny efekt
  </div>
  <div class="faces__btn">
    <button class="global-button" type="button" @click="handleClick">
      <span>przeciągnij wszystkie</span>
    </button>
  </div>
  <div class="faces__list">
    <ProductItem
        v-for="(i, index) in item.faces"
        :key="i.id"
        :item="i"
        :index="index"
        :face="true"
        :changeOverflow="handleChangeOverflow"
    />
  </div>
</div>
</template>

<script>
import PrevButton from '@/components/PrevButton';
import SectionTitle from '@/components/SectionTitle';
import ProductItem from '@/layouts/Aside/ProductItem';
import { usePanelStore } from '@/stores/usePanelStore'
import { getSizeFromMm } from '@/helpers/getSizeFromMm';

export default {
  name: "Faces",
  components: {
    PrevButton,
    SectionTitle,
    ProductItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    onClose: Function,
  },
  data() {
    return {
      faceStyle: {
        overflow: 'auto'
      }
    }
  },
  setup() {
    const panelStore = usePanelStore()
    return { panelStore }
  },
  methods: {
    handleClick() {
      let width = this.panelStore.getScrollLeft / this.panelStore.getZoom
      let height = this.panelStore.getScrollTop / this.panelStore.getZoom
      let newItems = []
      for (const el of this.item.faces) {
        const item = {
          element: el,
          x: width,
          y: height,
        }
        newItems.push(item)
        width += getSizeFromMm(el.width)
      }
      this.panelStore.setMoreElements(newItems)
    },
    handleChangeOverflow(overflow = 'auto') {
      this.faceStyle = {
        overflow: overflow,
        maxHeight: overflow === 'auto' ? 'calc(100% - 72px)' : 'unset'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.faces {
  @include scrool-style();
  position: fixed;
  bottom: 0;
  padding: 40px 31px 20px 31px;
  background: $white;
  width: 308px;
  box-shadow: 0 -1px 4px rgba(48, 49, 53, 0.25);
  z-index: 10;
  @media ($xlg) {
    width: 408px;
  }
  max-height: calc(100% - 72px);
  &__prev {
    margin-bottom: 28px;
  }
  &__title {
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(32px);
    letter-spacing: 0.05em;
    margin-bottom: 13px;
    text-transform: uppercase;
    text-align: center;
  }
  &__info {
    font-weight: 200;
    font-size: rem(16px);
    line-height: rem(22px);
    text-align: center;
    margin-bottom: 27px;
  }
  &__btn {
    @include flex-center;
    margin-bottom: 27px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}
</style>