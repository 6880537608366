<template>
<div class="filter-item">
  <button type="button" class="filter-item__button" @click="handleOpen">
    <span>{{ item.translations.pl.title }}</span>
    <IconArrowDown :class="open && 'open'" />
  </button>
  <Transition>
    <div class="inputs" :class="`inputs__col2`" v-if="open">
      <div v-for="el in item.attributeitem" :key="el.uuid" class="input-item">
        <input
            type="radio"
            :name="item.importId"
            :id="`${item.importId}_${el.importId}`"
            :value="el.uuid"
            class="visuallyhidden"
            v-model="checked"
            @change="handleChange"
        >
        <label :for="`${item.importId}_${el.importId}`">
          <span>
            {{el.translations.pl.title}}
          </span>
        </label>
      </div>
    </div>
  </Transition>
</div>
</template>

<script>
import IconArrowDown from '@/assets/icons/arrowDown.svg'
import { useFilterStore } from '@/stores/useFilterStore'

export default {
  name: "FiltersItem",
  components: {
    IconArrowDown,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const filterStore = useFilterStore()
    return { filterStore }
  },
  data() {
    return {
      open: false,
      checked: []
    }
  },
  mounted() {
    const storeFilters = this.filterStore.getFiltersByKey(this.item.importId)
    this.checked = storeFilters !== undefined ? storeFilters : []
    this.filterStore.$subscribe((mutation, state) => {
      if (Object.keys(state.filters).length === 0) {
        this.checked = []
      } else if (state.filters[this.item.importId] !== undefined) {
        this.checked = state.filters[this.item.importId]
      }
    })
  },
  methods: {
    handleOpen() {
      this.open = !this.open
    },
    handleChange() {
      this.filterStore.setFilters(this.checked, this.item.importId)
    }
  },
}
</script>

<style scoped lang="scss">
.filter-item {
  margin-top: 16px;
  &__button {
    @include flex-between;
    width: 100%;
    padding-bottom: 6px;
    padding-left: 0;
    border-bottom: 0.6px solid $grey;
    span {
      font-size: rem(14px);
      line-height: rem(21px);
      font-variant: small-caps;
      text-transform: uppercase;
    }
    svg {
      transition: transform 0.2s;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.inputs {
  overflow: auto;
  max-height: 500px;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  padding: 30px 0;
  &__col2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &__col3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.input-item {
  label {
    position: relative;
    padding-left: 35px;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(20px);
    display: block;
    &:before {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: '';
      width: 18px;
      height: 18px;
      border: 1px solid $black;
    }
  }
  input:checked + label {
    &:before {
      background: $black;
    }
    &:after {
      position: absolute;
      display: block;
      left: 3px;
      top: 5px;
      content: '';
      width: 11px;
      height: 5px;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-45deg);
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: max-height 0.4s ease, padding 0.4s ease;
  overflow: hidden;
  padding: 30px 0;
}

.v-enter-from,
.v-leave-to {
  max-height: 0;
  overflow: hidden;
  padding: 0;
}
</style>