<template>
<div
    class="item-drag"
    v-bind="$attrs"
    ref="draggableItem"
    :class="item.active ? 'active' : ''"
    :style="{
      transform: `translate(${item.x * zoom}px, ${item.y * zoom}px)`,
      width: `${getWidth}px`,
      height: `${getHeight}px`,
      zIndex: item.zindex,
    }"
>
  <img
      :src="item.element.img"
      :alt="item.element.name"
      :width="getWidthImage"
      :height="getHeightImage"
      :style="getRotateImage"
      ref="img"
  >
</div>
  <div
      class="dots"
      :style="{
    width: `${getWidth}px`,
    height: `${getHeight}px`,
    left: `${item.x * zoom}px`,
    top: `${item.y * zoom}px`,
      }"
       :class="item.element.custom && 'dots__custom'"
      v-if="item.active"
      ref="resizeItem"
  >
    <div class="dots__item dots__item--top-left"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div class="dots__item dots__item--top-right"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div class="dots__item dots__item--bottom-right"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div class="dots__item dots__item--bottom-left"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
    <div v-if="!isMoreActive && getWidth > 30 && getHeight > 30 && !item.element.custom" class="dots__item"></div>
  </div>
</template>

<script>
import interact from 'interactjs'
import { usePanelStore } from '@/stores/usePanelStore'
import { getSizeFromMm, getSizeFromPx } from '@/helpers/getSizeFromMm';

export default {
  name: "ItemElement",
  props: {
    item: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Number,
      default: 1,
    },
    isMoreActive: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mousePosition: {
        x: 0,
        y: 0,
      },
      imagePosition: {
        x: 0,
        y: 0,
      }
    }
  },
  setup() {
    const panelStore = usePanelStore()
    return { panelStore }
  },
  mounted() {
    let draggable = this.$refs.draggableItem

    if (this.item.active) {
      const resizeItem = this.$refs.resizeItem
      this.initDraggable(resizeItem)
      if (!this.isMoreActive) {
        this.initResizing(resizeItem)
      }
    } else {
      this.initDraggable(draggable)
    }

    this.setImagePosition()
  },
  computed: {
    getWidth() {
      const width = (this.item.rotate / 90) % 2 ? this.item.element.height : this.item.element.width
      return getSizeFromMm(width) * this.zoom
    },
    getHeight() {
      const height = (this.item.rotate / 90) % 2 ? this.item.element.width : this.item.element.height
      return getSizeFromMm(height) * this.zoom
    },
    getWidthImage() {
      return getSizeFromMm(this.item.element.width) * this.zoom
    },
    getHeightImage() {
      return getSizeFromMm(this.item.element.height) * this.zoom
    },
    getRotateImage() {
      return {
        transform: `rotate(${this.item.rotate}deg)`,
        left: `${this.imagePosition.x}px`,
        top: `${this.imagePosition.y}px`
      }
    },
  },
  methods: {
    setImagePosition() {
      const leftImg = this.$refs.img.getBoundingClientRect()
      const leftItem = this.$refs.draggableItem.getBoundingClientRect()

      this.imagePosition = {
        x: leftItem.left - leftImg.left,
        y: leftItem.top - leftImg.top
      }
    },
    initDraggable(selector) {
      const panelStore = this.panelStore
      let item = this.item
      this.$watch('item', (newItem) => {
        item = newItem
      })
      let isMoreActive = this.isMoreActive
      this.$watch('isMoreActive', (newState) => {
        isMoreActive = newState
      })

      let zoom = this.zoom
      let position = { x: item.x * zoom, y: item.y * zoom }
      let changePosition = {x: 0, y: 0}
      selector.style.transition = `translate(${position.x}px, ${position.y}px)`
      this.$watch('zoom', (newState) => {
        zoom = newState
        position = { x: item.x * zoom, y: item.y * zoom }
        selector.style.transition = `translate(${position.x}px, ${position.y}px)`
      })

      let dynamicTargets = [];

      const snap = [
        interact.modifiers.snap({
          targets: dynamicTargets,
          relativePoints: [
            { x: 0  , y: 0   },
            { x: 1  , y: 1   },
            { x: 0  , y: 1   },
            { x: 1  , y: 0   },
          ],
          range: 10,
          offset: 'parent',
        }),
      ]

      const draggable = interact(selector)
      draggable.draggable({
        snap: true,
        inertia: true,
        cursorChecker () {
          panelStore.setMenuText('przenieś element')
          return 'all-scroll'
        },
        listeners: {
          start () {
             panelStore.getElements.forEach(el => {
               const width = getSizeFromMm(el.element.width) * zoom
               const height = getSizeFromMm(el.element.height)* zoom
               const x = el.x * zoom
               const y = el.y * zoom
              const obiectlt = {
                x: x,
                y: y,
              }
               dynamicTargets.push(obiectlt)

               const obiectrt = {
                 x: x + width,
                 y: y,
               }
               dynamicTargets.push(obiectrt)

               const obiectrb = {
                 x: x + width,
                 y: y + height,
               }
               dynamicTargets.push(obiectrb)

               const obiectlb = {
                 x: x,
                 y: y + height,
               }
               dynamicTargets.push(obiectlb)

               if (width > 30 && height > 30) {
                 const obiectt1 = {
                   x: x + (width * 0.25),
                   y: y,
                 }
                 dynamicTargets.push(obiectt1)
                 const obiectt2 = {
                   x: x + (width * 0.5),
                   y: y,
                 }
                 dynamicTargets.push(obiectt2)
                 const obiectt3 = {
                   x: x + (width * 0.75),
                   y: y,
                 }
                 dynamicTargets.push(obiectt3)
                 const obiectr1 = {
                   x: x + width,
                   y: y + (height * 0.25),
                 }
                 dynamicTargets.push(obiectr1)
                 const obiectr2 = {
                   x: x + width,
                   y: y + (height * 0.5),
                 }
                 dynamicTargets.push(obiectr2)
                 const obiectr3 = {
                   x: x + width,
                   y: y + (height * 0.75),
                 }
                 dynamicTargets.push(obiectr3)
                 const obiectb1 = {
                   x: x + (width * 0.25),
                   y: y + height,
                 }
                 dynamicTargets.push(obiectb1)
                 const obiectb2 = {
                   x: x + (width * 0.5),
                   y: y + height,
                 }
                 dynamicTargets.push(obiectb2)
                 const obiectb3 = {
                   x: x + (width * 0.75),
                   y: y + height,
                 }
                 dynamicTargets.push(obiectb3)
                 const obiectl1 = {
                   x: x,
                   y: y + (height * 0.25),
                 }
                 dynamicTargets.push(obiectl1)
                 const obiectl2 = {
                   x: x,
                   y: y + (height * 0.5),
                 }
                 dynamicTargets.push(obiectl2)
                 const obiectl3 = {
                   x: x,
                   y: y + (height * 0.75),
                 }
                 dynamicTargets.push(obiectl3)
               }
            })
          },
          move (event) {
            position.x += event.dx
            changePosition.x += event.dx
            position.y += event.dy
            changePosition.y += event.dy
            if (isMoreActive) {
              panelStore.getActiveElements.forEach(el => {
                panelStore.updateElementWithoutHistoryById(el.id,{ x: el.x + (event.dx / zoom), y: el.y + (event.dy / zoom) })
              })
            } else {
              panelStore.updateElementWithoutHistoryById(item.id,{ x: position.x / zoom, y: position.y / zoom })
            }
          },
          end (event) {
            if (isMoreActive) {
              let newElements = []
              panelStore.getActiveElements.forEach(el => {
                const newItem = {
                  id: el.id,
                  object: { x: el.x + (event.dx / zoom), y: el.y + (event.dy / zoom) }
                }
                newElements.push(newItem)
              })
              panelStore.updateMoreElementById(newElements)
            } else {
              panelStore.updateElementById(item.id,{ x: position.x / zoom, y: position.y / zoom })
            }
            dynamicTargets = []
          }
        },
        modifiers: [
          ...snap,
          interact.modifiers.restrict({
            restriction: selector.parentNode,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true
          })
        ],
      })
      draggable.on('tap', (e) => {
        if (e.originalEvent.ctrlKey) {
          panelStore.updateElementById(item.id, { active: !item.active })
        } else {
          panelStore.getActiveElements.forEach(el => {
            panelStore.updateElementById(el.id, { active: false })
          })
          if (!item.active) {
            panelStore.updateElementById(this.item.id, { active: true })
          }
        }
      })
    },
    initResizing(selector) {
      const panelStore = this.panelStore
      const width = this.getWidth
      const height = this.getHeight
      let item = this.item
      this.$watch('item', (newItem) => {
        item = newItem
      })

      let zoom = this.zoom
      this.$watch('zoom', (newState) => {
        zoom = newState
      })

      let aspectRatio = []
      let restrictSize = []
      let snapSize = []
      if (item.element.custom) {
        aspectRatio = [
          interact.modifiers.aspectRatio({
            ratio: 'preserve',
          }),
        ]
      }
      if (!item.element.custom) {
        restrictSize = [
          interact.modifiers.restrictSize({
            min: { width: width, height: height }
          }),
        ]
        snapSize = [
          interact.modifiers.snapSize({
            targets: [ interact.snappers.grid({ x: width, y: height }) ],
          }),
        ]
      }
      const resizable = interact(selector)
      resizable.resizable({
        edges: {
          left: true,
          right: true,
          bottom: true,
          top: true
        },
        square: false,
        squareResize: false,
        preserveAspectRatio: false,
        cursorChecker (action) {
          if (action.edges.left || action.edges.right || action.edges.top || action.edges.bottom) {
            panelStore.setMenuText(item.element.custom ? 'Zmień rozmiar' : 'Powiel element')
          }

          if (action.edges.left && action.edges.top) { return 'nw-resize'}
          if (action.edges.left && action.edges.bottom) { return 'sw-resize'}
          if (action.edges.right && action.edges.bottom) { return 'se-resize'}
          if (action.edges.right && action.edges.top) { return 'ne-resize'}
          if (action.edges.left) { return 'w-resize' }
          if (action.edges.right) { return 'e-resize' }
          if (action.edges.top) { return 'n-resize' }
          if (action.edges.bottom) { return 's-resize' }
        },
        listeners: {
          move (event) {

            const target = event.target
            let x = (parseFloat(target.getAttribute('data-x')) || 0)
            let y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
          },
          end (event) {
            const target = event.target

            if (item.element.custom) {
              const newWidth = event.rect.width
              const newHeight = event.rect.height
              const x = target.getAttribute('data-x')
              const y = target.getAttribute('data-y')
              panelStore.updateElementById(item.id, {
                x: item.x + (x / zoom),
                y: item.y + (y / zoom),
                element: {
                  ...item.element,
                  width: getSizeFromPx(newWidth / zoom),
                  height: getSizeFromPx(newHeight / zoom),
                }
              })
              target.style.width = newWidth + 'px'
              target.style.height = newHeight + 'px'
            } else {
              const x = event.rect.width / width
              const y = event.rect.height / height
              let newItems = []
              for (let i = 0; i < x; i++ ) {
                for (let j = 0; j < y; j++) {
                  if (i > 0 || j > 0) {
                    const newItem = {
                      ...item,
                      x: event.edges.left ? item.x - (width / zoom * i) : item.x + (width / zoom * i),
                      y: event.edges.top ? item.y - (height / zoom * j) : item.y + (height / zoom * j),
                    }
                    newItems.push(newItem)
                  }
                }
              }
              panelStore.setMoreElements(newItems)
              target.style.width = width + 'px'
              target.style.height = height + 'px'
            }
            target.style.transform = 'translate(0px,0px)'
            target.setAttribute('data-x', 0)
            target.setAttribute('data-y', 0)
          }
        },
        modifiers: [
          ...restrictSize,
          ...snapSize,
          ...aspectRatio,
        ],
        inertia: true
      })
      .on('mouseleave', () => {
        if (item.active) {
          panelStore.setMenuText('przenieś element')
        }
      })
    },
    unsetInteract(selector) {
      interact(selector).unset()
    }
  },
  watch: {
    item(newItem, oldItem) {
      let draggable = this.$refs.draggableItem

      if (oldItem.active !== newItem.active && newItem.active) {
        this.unsetInteract(draggable)
        setTimeout(() => {
          const resizeItem = this.$refs.resizeItem

          this.initDraggable(resizeItem)
          if (!this.isMoreActive) {
            this.initResizing(resizeItem)
          }
        }, 100)
      } else if(oldItem.active !== newItem.active && !newItem.active) {
        this.initDraggable(draggable)
      }
      if (newItem.rotate !== oldItem.rotate) {
        this.imagePosition = {
          x: 0,
          y: 0
        }
        setTimeout(() => {
          this.setImagePosition()
        }, 0)
      }
    },
    isMoreActive(newState) {
      if (this.item.active) {
          setTimeout(() => {
            const resizeItem = this.$refs.resizeItem
            if (newState) {
              this.unsetInteract(resizeItem)
              this.initDraggable(resizeItem)
            } else {
              this.initResizing(resizeItem)
            }
          }, 100)
      }
    },
    zoom() {
      this.imagePosition = {
        x: 0,
        y: 0
      }
      setTimeout(() => {
        this.setImagePosition()
      }, 0)
    }
  }
}
</script>

<style scoped lang="scss">
.item-drag {
  display: block;
  position: absolute;
  //overflow: hidden;
  line-height: 0;
  img {
    object-fit: cover;
    object-position: center;
    top: 0;
    transform-origin: center;
    position: absolute;
  }
  &.active {
    filter: drop-shadow(0px 6.90756px 6.90756px rgba(48, 49, 53, 0.25));
  }
}
.dots {
  $this: &;
  position: absolute;
  //width: calc(100%);
  //height: calc(100%);
  //left: 0;
  //top: 0;
  z-index: 20;
  border: 1px solid $red;
  background: rgba(193, 11, 38, 0.1);
  &__item {
    position: absolute;
    display: block;
    border-radius: 100%;
    background: $red;
    width: 8px;
    height: 8px;
    &--top-left {
      width: 16px;
      height: 16px;
      left: -8px;
      top: -8px;
    }
    &:nth-of-type(2):not(.dots__item--top-right) {
      left: calc(25% - 4px);
      top: -4px;
    }
    &:nth-of-type(3):not(.dots__item--bottom-right) {
      left: calc(50% - 4px);
      top: -4px;
    }
    &:nth-of-type(4):not(.dots__item--bottom-left) {
      left: calc(75% - 4px);
      top: -4px;
    }
    &--top-right {
      width: 16px;
      height: 16px;
      right: -8px;
      top: -8px;
    }
    &:nth-of-type(6) {
      top: calc(25% - 4px);
      right: -4px;
    }
    &:nth-of-type(7) {
      top: calc(50% - 4px);
      right: -4px;
    }
    &:nth-of-type(8) {
      top: calc(75% - 4px);
      right: -4px;
    }
    &--bottom-right {
      width: 16px;
      height: 16px;
      right: -8px;
      bottom: -8px;
    }
    &:nth-of-type(10) {
      left: calc(75% - 4px);
      bottom: -4px;
    }
    &:nth-of-type(11) {
      left: calc(50% - 4px);
      bottom: -4px;
    }
    &:nth-of-type(12) {
      left: calc(25% - 4px);
      bottom: -4px;
    }
    &--bottom-left {
      width: 16px;
      height: 16px;
      left: -8px;
      bottom: -8px;
    }
    &:nth-of-type(14) {
      left: -4px;
      top: calc(75% - 4px);
    }
    &:nth-of-type(15) {
      left: -4px;
      top: calc(50% - 4px);
    }
    &:nth-of-type(16) {
      left: -4px;
      top: calc(25% - 4px);
    }
  }

  &__custom {
    border-color: $customDots;
    #{$this}__item {
      background-color: $customDots;
    }
  }
}
</style>