<template>
  <button
      type="button"
      class="button"
      :disabled="disabled"
      @click="onClick"
  >
    <IconArrow class="button__arrow" />
    <span class="button__text">{{ text }}</span>
  </button>
</template>

<script>
import IconArrow from '@/assets/icons/arrow.svg'
export default {
  name: "PrevButton",
  components: {
    IconArrow
  },
  props: {
    text: String,
    onClick: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped lang="scss">
.button {
  $this: &;
  @include flex-start;
  &__arrow {
    transform: rotate(180deg);
    margin-right: 20px;
    transition: all 0.2s;
  }
  &__text {
    font-weight: 500;
    color: $black;
    font-size: rem(14px);
    line-height: 1.43;
    letter-spacing: 0.02em;
  }
  &:hover {
    #{$this}__arrow {
      transform: rotate(180deg) translateX(10px);
    }
  }
  &:disabled {
    pointer-events: none;
    #{$this}__arrow {
      opacity: 0.3;
    }
    #{$this}__text {
      color: $grey;
    }
  }

}
</style>