import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => {
    return {
      notifications: [],
      sendEmailStatus: false,
    }
  },
  getters: {
    getNotifications(state) {
      return state.notifications
    },
    getSendEmailStatus(state) {
      return state.sendEmailStatus
    }
  },
  actions: {
    addNotification(element) {
      const lastElement = this.notifications[this.notifications.length - 1]
      let id = lastElement ? lastElement.id + 1 : 1
      this.notifications.push({...element, id: id})
    },
    removeNotification(id) {
      const foundIndex = this.notifications.findIndex(x => x.id === id)
      if (foundIndex > -1) {
        this.notifications.splice(foundIndex, 1)
      }
    },
    setSendEmailStatus(status) {
      this.sendEmailStatus = status
    }
  }
})