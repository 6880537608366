<template>
  <AppBar />
  <div class="wrapper">
    <Aside />
    <Panel />
  </div>
    <TransitionGroup
        tag="div"
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
    >
      <Notification v-for="(item, key) in notificationsStore.getNotifications" :key="key" :item="item" />
    </TransitionGroup>
</template>

<script>
import AppBar from '@/layouts/AppBar/AppBar';
import Aside from '@/layouts/Aside/Aside';
import Panel from '@/layouts/Panel/Panel';
import Notification from '@/components/Notification';
import { useNotificationStore } from '@/stores/useNotificationStore';
import gsap from 'gsap';

export default {
  name: 'App',
  components: {
    AppBar,
    Aside,
    Panel,
    Notification,
  },
  setup() {
    const notificationsStore = useNotificationStore()
    return {
      notificationsStore
    }
  },
  methods: {
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        height: '70px',
        delay: el.dataset.index * 0.1,
        onComplete: done
      })
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        delay: el.dataset.index * 0.1,
        onComplete: done
      })
    }
  }
}
</script>

<style lang="scss">
/* global styles */
@import "@/style/base/index.scss";
</style>

<style scoped lang="scss">
// local styles (scoped)
  @import "@/style/components/app.scss";
</style>