<template>
<aside class="aside">
  <Search />
  <Filters />
  <SelectedFilters />
  <Products />
</aside>
</template>

<script>
import { useFilterStore } from '@/stores/useFilterStore'
import Search from '@/layouts/Aside/Search';
import Filters from '@/layouts/Aside/Filters';
import SelectedFilters from '@/layouts/Aside/SelectedFilters';
import Products from '@/layouts/Aside/Products';

export default {
  name: "Aside",
  components: {
    Search,
    Filters,
    SelectedFilters,
    Products,
  },
  setup() {
    const filterStore = useFilterStore()
    return { filterStore }
  },
  methods: {
    handleSearch(e) {
      this.filterStore.setSearch(e.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.aside {
  background: $white;
  border-right: 0.5px solid $brown;
  height: 100%;
  position: relative;
  //overflow-y: hidden;
  //overflow-x: visible;
  //max-height: calc(100vh - 72px);
}
</style>

