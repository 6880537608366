<template>
<div class="selected-filters" v-if="filterStore.getSearch || Object.keys(filterStore.getFilters).length > 0">
  <SectionTitle title="Wybrane filtry" light />
  <div class="selected-filters__container">
    <div class="filter-item" v-if="filterStore.getSearch">
      <button class="filter-item__btn" @click="handleRemoveSearch">
        <IconClose />
      </button>
      <div class="filter-item__title">
        <span>
        {{ filterStore.getSearch }}
        </span>
      </div>
    </div>
<!--    <template v-for="(value, key) in filterStore.getFilters">-->
      <div class="filter-item" v-for="(value, key) in filterStore.getFilters" :key="value">
        <button class="filter-item__btn" @click="handleRemoveItem(key)">
          <IconClose />
        </button>
        <div class="filter-item__title">
          <span>
          {{ getItemFromFilters(value, key) }}
          </span>
        </div>
      </div>
<!--    </template>-->
  </div>
  <div class="selected-filters__reset">
    <button type="button" @click="handleReset">
      <IconReset />
      <span>resetuj filtry</span>
    </button>
  </div>
</div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle';
import IconClose from '@/assets/icons/close.svg'
import IconReset from '@/assets/icons/reset.svg'
import { useFilterStore } from '@/stores/useFilterStore'
import { filters } from '@/schema/filters';
export default {
  name: "SelectedFilters",
  components: {
    SectionTitle,
    IconClose,
    IconReset,
  },
  setup() {
    const filterStore = useFilterStore()
    const filtersSchema = filters
    return { filterStore, filtersSchema }
  },
  computed: {
    getItemFromFilters() {
      return (id, key) => {
        const filterDef = this.filterStore.getFiltersDef.find(i => i.importId === Number(key))
        return filterDef.attributeitem.find(i => i.uuid === id).translations.pl.title
      }
    }
  },
  methods: {
    handleReset() {
      this.filterStore.resetFilters()
    },
    handleRemoveSearch() {
      this.filterStore.setSearch('')
    },
    handleRemoveItem(key) {
      this.filterStore.removeItemFilter(key)
    }
  }
}
</script>

<style scoped lang="scss">
.selected-filters {
  padding: 20px 31px 20px 31px;
  &__container {
    @include flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 28px;
    margin-bottom: 21px;
  }
  &__reset {
    @include flex-end;
    button {
      @include flex-center;
      span {
        margin-left: 11px;
        font-size: rem(14px);
        text-transform: lowercase;
      }
      &:hover {
        span {
          color: $brown;
        }
      }
    }
  }
}
.filter-item {
  @include flex-center;
  border: 0.7px solid $black;
  border-radius: 32px;
  height: 35px;
  padding-left: 6px;
  padding-right: 18px;
  &__title {
    font-weight: 300;
    font-size: rem(14px);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    span {
      @include limit-line-count(2);
    }
  }
}
</style>