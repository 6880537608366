<template>
  <button
      type="button"
      class="button"
      :class="site"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @click="onClick"
      aria-label="{{ helperText }}"
      :disabled="disabled"
  >
    <slot></slot>
    <Transition>
      <span v-if="show" class="tooltip">{{ helperText }}</span>
    </Transition>
  </button>
</template>

<script>
export default {
  name: "Tooltip",
  data() {
    return {
      show: false,
      delay: false,
    }
  },
  props: {
    helperText: String,
    onClick: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
    site: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['top', 'bottom', 'right', 'left'].includes(value)
      }
    }
  },
  methods: {
    handleMouseEnter() {
      this.delay = true
    },
    handleMouseLeave() {
      this.delay = false
      this.show = false
    },
    setShow() {
      setTimeout( () => {
        if (this.delay) {
          this.show = true
        }
      }, 500)
    }
  },
  watch: {
    delay() {
      this.setShow()
    },
    disabled(newState) {
      if (newState) {
        this.show = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .tooltip {
    @include flex-center;
    @include zirkon(200);
    font-size: rem(14px);
    pointer-events: none;
    position: absolute;
    padding: 8px 20px;
    background: $white;
    max-width: 200px;
    width: max-content;
    border: 0.5px solid $brown;
    box-shadow: 0 2px 2px rgba(48, 49, 53, 0.25);
    z-index: 100;
  }
  .button {
    @include flex-center;
    padding: 0;
    position: relative;
    &.bottom {
      .tooltip {
        top: 105%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.top {
      .tooltip {
        bottom: 105%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.left {
      .tooltip {
        right: 110%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.right {
      .tooltip {
        left: 105%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &[disabled] {
      opacity: 0.2;
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.2s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>